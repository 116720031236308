import callFormPaths from "@root/constants/callFormPaths";
import getOrderCallForm from "@/call-form/helpers/getOrderCallForm";
import checkTimeForCallForm from "./helpers/checkTimeForCallForm";
import getPathWithoutBrand from "@root/tools/front_utils/getPathWithoutBrand";


export default function sourceCallFormState(selector) {
  const path = getPathWithoutBrand();

  if (callFormPaths.includes(path)) {
    checkTimeForCallForm(getOrderCallForm, selector)
  }
}