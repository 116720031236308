import startTimer from "@root/tools/front_utils/startTimer";
import reorderCall from "./reorderCall";
import changeFormAfterOrder from "./changeFormAfterOrder";

export default function orderCall() {
  $("#orderCallBtn").on("click", function () {
    const phone = $("#orderCallPhone").val();
    if (phone.length === 13 && phone.startsWith("+380")) {
      $.ajax({
        url: "ajaxrout.php?JSON=1&file=do/do_api_info.php&getData=CreateCallRequest",
        dataType: "json",
        type: "post",
        data: {
          phone,
          message: "Замовлення зворотнього дзвінка"
        },
        async: true,
        success: (res) => {
          localStorage.setItem("timerStartTime", new Date().getTime());
          changeFormAfterOrder();
          startTimer(120, ".timer-wr", "timer", reorderCall);
        }
      });
    } else {
      $("#orderCallPhone").addClass("is-invalid");
    }
  });
}