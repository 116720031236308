import sourceCallFormState from "@root/src/call-form/sourceCallFormState";
import getPathWithoutBrand from "@root/tools/front_utils/getPathWithoutBrand";
import pathForSidebarNav from "../constants/pathForSidebarNav";
import showSidebarContainer from "./showSidebarContainer";

async function loadSidebar(targetSelector) {
  const path = getPathWithoutBrand();

  if (pathForSidebarNav.includes(path)) {
    const response = await $.ajax({
      url: "site_templates/lehko/src/lehko-sidebar/templates/lehko_sidebar.htm",
      type: "get",
      cache: false,
    });
    $(`${targetSelector} .sidebar-container`).prepend(response);
    if ($(".sidebar-container").hasClass("d-none")) {
      showSidebarContainer();
    }
  }
}

export default async function getAppSidebar() {
  if (window.innerWidth > 992) {
    sourceCallFormState(".sidebar-container .order-call-wr");
    await loadSidebar(".main-content .wrapper");
  } else {
    sourceCallFormState("main ~ .order-call-wr");
    await loadSidebar("#account-mobile-menu");
  }
}
