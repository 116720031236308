import getPathWithoutBrand from "@root/tools/front_utils/getPathWithoutBrand";
import popupTexts from "../constants/popupTexts";
import counterOfferAndSignPaths from "../constants/counterOfferAndSignPaths";

export default function setPopup() {
    const path = getPathWithoutBrand();

    if (counterOfferAndSignPaths.includes(path)) {
        $(".offcanvas-title").html(popupTexts.counterOfferAndSignText.title);
        $(".offcanvas-text").html(popupTexts.counterOfferAndSignText.text);
    }

    const offcanvasCall = new bootstrap.Offcanvas(document.getElementById("offcanvasCall"));
    offcanvasCall.show();
}