import checkCallOrdering from "./checkCallOrdering";
import handlePhoneNumber from "./handlePhoneNumber";
import orderCall from "./orderCall";
import triggerCallPopupWithTime from "./triggerCallPopupWithTime";
import setOffcanvasCall from "./setOffcanvasCall";
import showSidebarContainer from "@root/src/application/helpers/showSidebarContainer";

export default async function getOrderCallForm(selector) {
  await $.ajax({
    url: "/site_templates/lehko/templates/order-call-form.htm",
    type: "get",
    cache: false,
    success: (res) => {
      $(selector).prepend(res);
      showSidebarContainer();
      handlePhoneNumber();
      orderCall();
      checkCallOrdering();
      triggerCallPopupWithTime();
      setOffcanvasCall();
    }
  });
}
