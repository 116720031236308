const popupTexts = {
    registrationText: {
      title: "Виникли складнощі?",
      text: "Замовте дзвінок і наша команда все владнає!",
    },
    counterOfferAndSignText: {
      title: "Отакої, щось пішло не за планом.",
      text: "Але не хвилюйтесь, гроші готові, наша служба підтримки також.<br/>Просто зверніться і ми все вирішимо!",
    },
  };
  
  export default popupTexts;
  