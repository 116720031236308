export default function checkTimeForCallForm(isTimeCallback, ...isTimeParams) {
    $.ajax({
        url: "custom_routes.php?action=isTime&controller=timeFormFront",
        type: "get",
        cache: false,
        success: async (res) => {
            if (res.status && typeof isTimeCallback === "function") {
                await isTimeCallback(...isTimeParams);
            }
        }
      });
  }