export default async function setOffcanvasCall() {
  const offcanvas = document.getElementById("offcanvasCall");
  offcanvas.addEventListener("show.bs.offcanvas", function () {
    if (window.innerWidth > 992) {
      $(".order-call__form").addClass("akcent-shadow");
    }
  });

  offcanvas.addEventListener("hidden.bs.offcanvas", function () {
    if (window.innerWidth > 992) {
      $(".order-call__form").removeClass("akcent-shadow");
    } else {
      window.scrollTo(0, $(".order-call__form").offset().top);
    }

  });
}