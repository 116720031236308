export default function startTimer(seconds, targetSelector, timerClass, callback, callbackArgs) {
  $(targetSelector).append(`<span class='timer-wrapper'>&nbsp;<span class=${timerClass}></span></span>`);

  let interval = setInterval(function () {
    if (seconds) {
      seconds--;
      $("." + timerClass).text(seconds);
    } else {
      clearInterval(interval);
      if (typeof callback === "function") {
        callbackArgs ? callback(...callbackArgs) : callback();
      }
    }
  }, 1000);
}
