import changeFormAfterOrder from "./changeFormAfterOrder";
import reorderCall from "./reorderCall";
import startTimer from "@root/tools/front_utils/startTimer";

export default function checkCallOrdering() {
  const timerStartTime = localStorage.getItem("timerStartTime");

  if (timerStartTime) {
    const timerStartTimeMillis = parseInt(timerStartTime, 10),
      currentTimeMillis = new Date().getTime(),
      timeDiff = Math.floor((currentTimeMillis - timerStartTimeMillis) / 1000);
    if (timeDiff < 120) {
      changeFormAfterOrder();
      startTimer((120 - timeDiff), ".timer-wr", "timer", reorderCall);
    }
  }
}