export default function handlePhoneNumber() {
  let telValue = $("#orderCallWrMain").data("tel").toString();
  if (!telValue.startsWith("+")) {
    telValue = "+" + telValue;
  }

  $("#orderCallPhone").val(telValue);
  $("#orderCallPhone").on("input", function () {
    const prefix = "+380";
    const inputPhone = $(this).val();
    if (inputPhone.length < prefix.length) {
      $(this).val(prefix);
    } else if (inputPhone.substring(0, prefix.length) !== prefix) {
      $(this).val(prefix + inputPhone.substring(prefix.length));
    }
  });

  $("#orderCallPhone").on("focus", function () {
    $(this).removeClass("is-invalid");
  });
}