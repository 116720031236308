import orderCall from "./orderCall";

export default function reorderCall() {
  $(".timer-wrapper").remove();
  $(".timer-wr").addClass("d-none");
  $(".order-call__title").text("Не вдається продовжити самостійно?");
  $("#subTitle, .kacha-input-wrapper").removeClass("d-none");
  $(".order-call-btn").removeClass("disabled");

  $("#orderCallForm").off().on("click", function () {
    orderCall();
  });
}