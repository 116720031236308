const callFormPaths = [
  "/add-contact",
  "/verification-bank-id",
  "/credit-step-counter-offer",
  "/job",
  "/address",
  "/reconnection",
  "/credit-card-select",
  "/passport-update",
  "/anketa-selfi-personal-foto",
  "/anketa-selfi-personal-foto-2",
  "/signing-contract",
  "/signing-credit-passport",
  "/signing-consent-form",
  "/approval-personal-data",
  "/anketa-data-coincide",
  "/ipn-repeat",
  "/change-phone-to-financial",
  "/credit-3",
  "/credit-issue-error",
  "/credit-5",
  "/credit-2",
  "/credit-step-registration-address",
  "/credit-step-passport-data",
  "/signing-partial-schedule"
];

export default callFormPaths;
